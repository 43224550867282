<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      :span-method="arraySpanMethod"
    >
      <el-table-column align="center" prop="cangku_name" label="出货仓库" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="amount" label="数量" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="date" label="调配时间" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" min-width="20%"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" min-width="15%"> </el-table-column>
      <el-table-column align="center" label="管理" min-width="15%">
        <template slot-scope="scope" v-if="!(scope.row.type === '2' || scope.row.type === '3')">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="userType.wuzidiaopei_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.wuzidiaopei_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },

    //设置小计和合计行的状态
    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.type === '2') {
        return 'xiaoji-row'
      } else if (row.type === '3') {
        return 'heji-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return 'normal-row'
    },
    //小计和合计列合并
    arraySpanMethod({ row, columnIndex }) {
      // console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 3]
        } else if (columnIndex === 2) {
          return [0, 0]
        }
      }
    }
  }
}
</script>
